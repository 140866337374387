import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// Test Firebase
// const firebaseConfig = {
//   apiKey: "AIzaSyBiDqNww9u2C2-3M7MGFlaDqOru2IlsFSQ",
//   authDomain: "collabscafe-9972a.firebaseapp.com",
//   projectId: "collabscafe-9972a",
//   storageBucket: "collabscafe-9972a.appspot.com",
//   messagingSenderId: "577054629274",
//   appId: "1:577054629274:web:1bd72cb287520ce1382e23",
//   measurementId: "G-F5VLYEJ32K"
// };

// Production Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAQ7jNl8_Se6w4QkCottmWr7xv5RhFyXzU",
  authDomain: "collabscafe-61276.firebaseapp.com",
  projectId: "collabscafe-61276",
  storageBucket: "collabscafe-61276.appspot.com",
  messagingSenderId: "1033524681943",
  appId: "1:1033524681943:web:793567e01ebadebabfa492",
  measurementId: "G-389KC0BHJV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const provider = new GoogleAuthProvider();

